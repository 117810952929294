import React from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const Banner = (props) => {
  
  const location = useLocation();
  const local = location.pathname;
  const pathnameArray = local.split("/");
  pathnameArray.shift();
  const { assistedPurchaseState } = useAuth();

  return (
    <>
      {assistedPurchaseState && (
        <div
          style={{
            display: "flex",
            background: "#212529",
            justifyContent: "center",
            marginBottom: "120px",
            alignItems: "center",
            color: "white",
            height: "70px",
          }}
        >
          Atenção! Você está ao vivo na conta do colaborador{" "}
          {assistedPurchaseState.clientName}.
        </div>
      )}
    </>
  );
};

export default Banner;
