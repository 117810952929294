import React, { createContext, useState, useEffect, useCallback } from "react";
import { useHttp } from "../hooks/useHttp";
import useDebounce from "../hooks/useDebounce";

export const ProductContext = createContext({});
export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);

  const [productOrder, setProductOrder] = useState({
    type: "Alphabetic",
    order: "ASC",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [productFilters, setProductFilters] = useState([]);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);
  const { sendRequest } = useHttp();
  useEffect(() => setPage(1), [categories, debouncedSearch, productOrder]);

  const ProductQueryObj = useCallback(() => {
    return {
      page: page,
      filters: {
        category: categories,
        search: debouncedSearch,
        order: productOrder,
      },
    };
  }, [page, categories, debouncedSearch, productOrder]);

  useEffect(() => {
    const loadProducts = async () => {
      setIsLoading(true);
      const responseData = await sendRequest(
        "https://matrixterra.com.br/ws/customer/products.php",
        "POST",
        JSON.stringify(ProductQueryObj()),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success) {
        setProducts(responseData);
      } else {
        throw new Error(responseData.message);
      }
    };
    loadProducts();
    setIsLoading(false);
  }, [ProductQueryObj, sendRequest]);

  useEffect(() => {
    const loadFilters = async () => {
      setIsLoading(true);
      const responseData = await sendRequest(
        "https://matrixterra.com.br/ws/customer/products_category.php",
        "GET",
        null,
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success) {
        setProductFilters(responseData.categories);
      } else {
        throw new Error(responseData.message);
      }
    };
    loadFilters();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProductContext.Provider
      value={{
        products: products.products,
        productQuery: {
          total_pages: products.total_pages,
          actual_page: products.actual_page,
          total_products: products.total_products,
        },
        productOrder: productOrder,
        isLoading,
        productFilters,
        page,
        categories,
        search,
        setPage,
        setCategories,
        setSearch,
        setProductOrder,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
