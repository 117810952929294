import React, { useState } from "react";
import KeywordFilter from "../MyAccount/components/KeywordFilter";
import { Link } from "react-router-dom";
import styles from "../MyClients/myclients.module.css";
import useMyClients from "../../hooks/useMyClients";

const MyClients = () => {
  const [keyword, setKeyword] = useState("");
  const { companyClients, isLoading } = useMyClients();

  const iconStyle = { color: "#EC1F25" };
  const buttonContainerStyle = { display: "flex", gap: "10px" };

  const handleFilterChange = (event) => {
    setKeyword(event.target.value);
  };

  return (
    <div className="ltn__my-properties-table table-responsive container my-5">
      <>
        <h4 className="title-2">Meus Clientes</h4>
        <div
          style={{
            width: "100%",
            height: "auto",
            fontWeight: 700,
            background: "#F2F6F7",
            padding: "15px",
            marginBottom: "10px",
          }}
        >
          Esta é a área de gerenciamento de clientes Terra, onde você poderá visualizar o carrinho de seus clientes e auxiliá-los em suas compras. 
        </div>
        <KeywordFilter keyword={keyword} onFilterChange={handleFilterChange} />
        <table className="table mt-5">
          <thead
            style={{
              borderRight: "hidden",
              borderLeft: "hidden",
              borderTop: "hidden",
            }}
          >
            <tr>
              <th scope="col">Unidade</th> <th scope="col" />
              <th
                scope="col"
                style={{ textAlign: "end", paddingRight: "30px" }}
              >
                Ver perfis das Unidades
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              borderRight: "hidden",
              borderLeft: "hidden",
              fontSize: "14px",
            }}
          >
            {isLoading ? (
              <h1>Loading....</h1>
            ) : (
              companyClients
                .filter((company) => company.fantasy_name.includes(keyword))
                .map((e) => (
                  <tr key={e.id} className="mb-5">
                    <td
                      style={{
                        width: "180px",
                      }}
                    >
                      <img
                        src={
                          "https://img.freepik.com/free-photo/workman-car-repair-shop-changing-tires_1303-31850.jpg?w=740&t=st=1702096373~exp=1702096973~hmac=64e7d886ab70700e5474ce2d5341fe4d7e74b629494843dbcbedb2964ea2d41d"
                        }
                        alt="Author"
                        style={{
                          width: "164px",
                          height: "124px",
                        }}
                      />
                    </td>
                    <td>
                      <h2 className={styles.nameCollaborator}>
                        {e.fantasy_name}
                      </h2>
                      <p className={styles.description}>
                        Este cliente possui {e.num_collaborators} Colaboradores
                      </p>

                      <div className="footer-address">
                        <div
                          className="button-container"
                          style={buttonContainerStyle}
                        >
                          {
                            e.phone && <a
                              className={styles.btn}
                              target="_blank"
                              href={`https://api.whatsapp.com/send?phone=${e.phone}`}
                              rel="noreferrer"
                            >
                              <i className="fab fa-whatsapp" style={iconStyle} />
                            </a>
                          }
                          {
                            e.corporate_email && <a
                              className={styles.btn}
                              href={`mailto:${e.corporate_email}`}
                            >
                              <i className="far fa-envelope" style={iconStyle} />
                            </a>
                          }
                        </div>
                      </div>
                    </td>
                    <td style={{ textAlign: "end" }}>
                      <Link to={e.id}>
                        <button
                          className="theme-btn-1 btn btn-block"
                          type="submit"
                        >
                          <i className="fas fa-search" />
                          Ver Colaboradores do Cliente
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </>
    </div>
  );
};
export default MyClients;
