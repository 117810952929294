import React from "react";
import { whatsapp_links } from "../../../data/contact";
const AuthInfo = () => {
  return (
    <div>
      <div className=" align-self-center mb-100">
        <div className="auth-info-wrap">
          <h3 className="form-text-home text-center">
            Conheça as vantagens de ter uma conta Terra
          </h3>
          <p className="text-center">
            Torne-se nosso parceiro e acesse nossa área exclusiva para empresas.
            Encomende produtos, gerencie seus pedidos, ganhe cashback e muito
            mais! Tudo em um só lugar. 😊
          </p>
          <div className="col-md">
            <div className="btn-wrapper mt-1 text-center">
              <a
                href={whatsapp_links.parceiro}
                target="_blank"
                className="btn theme-btn-1 btn-effect-1 align-self-auto"
                rel="noreferrer"
              >
                Me tornar um parceiro Terra
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthInfo;
