import React, { useEffect } from "react";
import Copyright from "./copyright";
import { useLocation } from "react-router-dom";

export default function Footer_v1() {
  useEffect(() => {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  const { pathname } = useLocation();

  const rightPath = (path) => {

    if (pathname == '/') {
      return path;
    };

    return '/' + path;
  };

  return (
    <footer className="ltn__footer-area  ">
      <div className="footer-top-area  section-bg-2 plr--5">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-4 col-md-4 col-sm-6 col-12">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <img
                      src={publicUrl + "assets/img/logo-footer.png"}
                      alt="Logo"
                    />
                  </div>
                </div>
                <p>Grupo Terra - Gente feliz fazendo bons negócios!</p>
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon colored-footer-icon">
                        <i className="fab fa-whatsapp"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:+51985530391">(51) 98553-0391</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="fa-solid fa-phone"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:+5551985530391">(51) 9 8553-0391</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:vendas@matrixterra.com.br">
                            vendas@matrixterra.com.br
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:adm@matrixterra.com.br">
                            adm@matrixterra.com.br
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Nossas áreas</h4>
                <div className="footer-menu">
                  <ul>
                    <li>
                      <a href={rightPath("#home")}>Home</a>
                    </li>
                    <li>
                      <a href={rightPath("#products")}>Produtos</a>
                    </li>
                    <li>
                      <a href={rightPath("#about-us")}>Sobre nós</a>
                    </li>
                    <li>
                      <a href={rightPath("#contact")}>Contato</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}
