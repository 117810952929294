import React from "react";
import TotalCartTable from "./components/TotalCartTable";
import { Link } from "react-router-dom";

import Cart from "./components/Cart";
import useCart from "../../hooks/useCart";

const CartLayout = () => {

  const {
    cart,
  } = useCart();

  const isBuying = cart.length > 0;

  return (
    <div className="liton__shoping-cart-area mb-120">
      <div className="container">
        <Cart />
        <div className="row justify-content-end">
          <div className="shoping-cart-total mt-50 justify-content-end">
            <TotalCartTable />
            {
              isBuying && <div className="btn-wrapper text-right go-top">
                <Link
                  to="/checkout"
                  className="theme-btn-1 btn btn-effect-1 w-100"
                >
                  Finalizar compra
                </Link>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  );
};
export default CartLayout;
