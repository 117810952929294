import React, { useState } from "react";
import { useHttp } from "../../hooks/useHttp";
import toast from "react-hot-toast";

const ChangePassword = ({ stepHandler, userId }) => {
  const { sendRequest } = useHttp();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const validateNewPassword = async () => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/forgotPassword/update_password.php",
      "POST",
      JSON.stringify({
        password: password1,
        password2: password2,
        id_usuario: userId,
      }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      stepHandler("4");
      toast.success(responseData.message);
    } else {
      toast.error(responseData.message);
      //   throw new Error(responseData.message);
    }
  };

  const submitHandler = () => {
    validateNewPassword();
  };

  const password1Handler = (e) => setPassword1(e.target.value);
  const password2Handler = (e) => setPassword2(e.target.value);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 className="form-text-home text-center">Altere sua senha</h1>
      <p className="text-center mb-50">
        Digite e confirme sua nova senha para fazer login em sua conta.
      </p>
      <input
        className="input-item input-item-email px-3 "
        style={{ width: "100%", maxWidth: "469px" }}
        placeholder="Senha Nova*"
        value={password1}
        type="password"
        onChange={password1Handler}
      />
      <input
        className="input-item input-item-email px-3 mt-3 "
        type="password"
        style={{ width: "100%", maxWidth: "469px" }}
        placeholder="Confirmar Senha Nova*"
        value={password2}
        onChange={password2Handler}
      />
      {password1 !== password2 && (
        <p className="ltn__primary-color">
          A senha de confirmação deve ser igual a nova senha!
        </p>
      )}
      <div className="btn-wrapper mt-0">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="submit"
          disabled={password1 !== password2}
          onClick={submitHandler}
          style={{
            maxWidth: "100%",
            width: "469px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          Alterar senha
        </button>
      </div>
    </div>
  );
};
export default ChangePassword;
