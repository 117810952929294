import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Cart from "../Cart/components/Cart";
import TotalCartTable from "../Cart/components/TotalCartTable";
import Input from "./../../components/FormComponents/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone-lite";
import useCart from "../../hooks/useCart";
import useMyAccount from "../../hooks/useMyAccount";
import { formatBRL } from "../../util/formatPriceBRL";
import { useAuth } from "../../hooks/useAuth";
import toast from "react-hot-toast";

const zipCodeRegexExp = new RegExp(`^[0-9]{5}[0-9]{3}$`);
const phoneNumberRegexExp = new RegExp(`^[0-9]{2}[0-9]{5}[0-9]{4}$`);

const checkoutValidationSchema = yup.object().shape({
  firstName: yup.string().required("Por favor insira um nome!"),
  lastName: yup.string().required("Por favor insira um sobrenome!"),
  email: yup
    .string()
    .email("Por favor insira um endereço de e-mail válido!")
    .required("Por favor insira um endereço de e-mail!"),
  phoneNumber: yup
    .string()
    .required("Por favor insira um número de telefone!")
    .matches(
      phoneNumberRegexExp,
      "Por favor insira um número de telefone válido"
    ),
  companyName: yup.string(),
  companyAddress: yup.string(),
  address: yup.string().required("Por favor insira um endereço!"),
  addressComplement: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipCode: yup
    .string()
    .required()
    .matches(zipCodeRegexExp, "Insira um CEP válido!"),
});

const Checkout = () => {
  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(checkoutValidationSchema),
  });

  const {
    finalizeCart,
    totalValue,
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
  } = useCart();

  const { userInfo } = useAuth();

  const finalizeCartHandler = async () => {
    if (!isPurchaseDisabled()) return finalizeCart();
    return null;
  };

  const { paymentDelivery } = useMyAccount();

  const isPurchaseDisabled = () => {
    return totalValue() >= userInfo?.user_buying_limit;
  };

  const paymentHandler = (payment_name) => setPaymentMethod(payment_name);

  return (
    <div className="container">
      <div className="ltn__checkout-single-content mt-50">
        <h4 className="title-2">Informações do responsável da compra</h4>
        <div className="ltn__checkout-single-content-info my-5">
          <h6>Informações pessoais</h6>
          <div className="row">
            <div className="col-md-6">
              <Input
                register={register}
                className={"input-item input-item-name mbn ltn__custom-icon"}
                type="text"
                fieldName="firstName"
                placeholder={paymentDelivery?.master_user_name}
                errors={errors}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                register={register}
                className={
                  "input-item input-item-email ltn__custom-icon mb-30"
                }
                type="text"
                fieldName="email"
                placeholder={paymentDelivery?.corporate_email}
                errors={errors}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                register={register}
                className="input-item input-item-phone ltn__custom-icon mb-30"
                type="text"
                fieldName="phoneNumber"
                errors={errors}
                placeholder={paymentDelivery?.phone}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                register={register}
                className="input-item input-item-website ltn__custom-icon mb-30"
                type="text"
                fieldName="companyName"
                placeholder={paymentDelivery?.fantasy_name}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h6>Endereço</h6>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    register={register}
                    className="input-item mb-30"
                    fieldName="address"
                    type="text"
                    placeholder={paymentDelivery?.address}
                    errors={errors}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    register={register}
                    className="input-item mb-30"
                    fieldName="addressComplement"
                    type="text"
                    placeholder={paymentDelivery?.complement}
                    errors={errors}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h6>Cidade</h6>
              <Input
                register={register}
                className="input-item mb-30"
                fieldName="city"
                type="text"
                placeholder={paymentDelivery?.city}
                errors={errors}
                disabled
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <h6>Estado</h6>
              <Input
                className="input-item"
                register={register}
                fieldName="state"
                type="text"
                placeholder={paymentDelivery?.state}
                errors={errors}
                disabled
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <h6>CEP</h6>
              <Input
                className="input-item"
                register={register}
                fieldName="zipCode"
                type="text"
                placeholder={paymentDelivery?.zip}
                errors={errors}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="my-5">
          <Cart />
        </div>
      </div>
      <div>
        <h4 className="title-2">Método de pagamento</h4>
      </div>
      <div
        style={{
          padding: "30px",
          margin: 0,
          border: "1px solid #e5eaee",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {paymentMethods.map((e) => (
            <label key={e.id}>
              <input
                style={{ margin: "10px 20px" }}
                className="ltn__primary-color"
                type="radio"
                checked={paymentMethod.payment_name === e.payment_name}
                value={paymentMethod.payment_name}
                onChange={() => paymentHandler(e)}
              />
              {e.payment_name}
            </label>
          ))}
        </div>
        <p className="mt-4 text-center">
          Caso queira modificar o método de pagamento, entre em contato com a
          Terra pelo e-mail adm@matrixterra.com.br ou pelo número (51) 9 8553-0391 (SAC)
        </p>
      </div>
      <div className="row justify-content-end mb-4">
        <div className="shoping-cart-total mt-50 justify-content-end">
          <TotalCartTable showCashbackMessage />
          <div className="btn-wrapper text-right go-top">
            <button
              onClick={finalizeCartHandler}
              disabled={isPurchaseDisabled() || paymentMethod === ""}
              className={`${isPurchaseDisabled()
                ? "theme-btn-1-opposite btn w-100"
                : "theme-btn-1 btn btn-effect-1 w-100"
                }`}
            >
              Finalizar compra
            </button>
          </div>
          <div className="mini-cart-sub-total">
            <strong>
              Limite de Compra:{" "}
              <span>{formatBRL(userInfo?.user_buying_limit)}</span>
            </strong>
          </div>
          {isPurchaseDisabled() && (
            <div>
              <strong>
                <span>
                  Valor total do carrinho ultrapassou limite máximo de compra!
                  Por favor retire items do seu carrinho para prosseguir com a
                  compra!
                </span>
              </strong>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Checkout;
