import { Link } from "react-router-dom";
import userImage from "../../../../../data/user_icon.jpg";
import styles from "../Collaborators.module.css";
import { stripNonNumeric } from "../../../../../util/stripNonNumeric";

export default function CollaboratorComponent({ collaborator }) {
    return (
        <tr className="mb-5">
            <td style={custom_styles.imgContainerStyle}>
                <img
                    src={
                        collaborator.image
                            ? `data:image/png;base64,${collaborator?.image}`
                            : userImage
                    }
                    alt="Author"
                    style={custom_styles.imgStyle}
                />
            </td>
            <td>
                <h2 className={styles.nameCollaborator}>
                    {collaborator?.full_name}
                </h2>
                {collaborator.function ? (
                    <p className={styles.description}>
                        {collaborator?.function} em{" "}
                        <span style={custom_styles.companyHighlightStyle}>
                            {collaborator?.company_name}
                        </span>
                    </p>
                ) : (
                    <p className={styles.description}>Não informado</p>
                )}
                <div className="footer-address">
                    <div
                        className="button-container"
                        style={custom_styles.buttonContainerStyle}
                    >
                        <Link to={"collabDetails"} state={{ collaborator }}>
                            <button
                                className="theme-btn-1 btn btn-block"
                                type="submit"
                                style={custom_styles.buttonStyle}
                            >
                                <i className="fas fa-search" />
                                Ver detalhes
                            </button>
                        </Link>
                        <a
                            className={styles.btn}
                            target="_blank"
                            rel="noreferrer"
                            href={`https://wa.me/55${stripNonNumeric(collaborator.whatsapp)}`}
                        >
                            <i
                                className="fab fa-whatsapp"
                                style={custom_styles.iconStyle}
                            />
                        </a>
                        <a
                            className={styles.btn}
                            href={`mailto:${collaborator.email}`}
                        >
                            <i
                                className="far fa-envelope"
                                style={custom_styles.iconStyle}
                            />
                        </a>
                    </div>
                </div>
            </td>
            <td style={custom_styles.textAlignCenterStyle}></td>
        </tr>
    );
};

const custom_styles = {
    iconStyle: { color: "#EC1F25" },
    buttonContainerStyle: { display: "flex", gap: "10px" },
    imgStyle: {
        width: "164px",
        height: "124px",
        objectFit: "cover",
    },
    buttonStyle: {
        padding: "8px 4px",
        minWidth: "180px",
        boxShadow: "var(--ltn__box-shadow-1)",
        border: "none",
    },
    companyHighlightStyle: { color: "#EC1F25", fontWeight: "700" },
    textAlignCenterStyle: { textAlign: "center" },
    imgContainerStyle: { width: "180px" },
};
