import { whatsapp_links } from "../../data/contact";

export default function CallToActonV1() {
  return (
    <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="call-to-action-inner call-to-action-inner-6 position-relative text-center---">
              <div className="coll-to-info text-color-white">
                <h1>Seja nosso parceiro!</h1>
                <p>
                  Conheça os benefícios de ter a Terra como parceira e
                  distribuidora.
                </p>
              </div>
              <div className="btn-wrapper go-top">
                <a
                  className="theme-btn-1 btn btn-effect-1"
                  href={whatsapp_links.parceiro}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Entrar em contato agora <i className="icon-next" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
