import React from "react";
import { Link } from "react-router-dom";
import CartTableHeader from "../../../Cart/components/CartTableHeader";
import useMyAccount from "../../../../hooks/useMyAccount";
import { hasImage } from "../../../Products/components/ProductItem";
import { formatBRL } from "../../../../util/formatPriceBRL";

export default function DetailsOrderHistory() {

  const { orderDetail } = useMyAccount();

  return (
    <div>
      <div className="container">
        <Link to="/MyAccount/orderHistory">
          <button
            style={{
              width: "max-content",
              marginBottom: "48px",
              borderRadius: 0,
            }}
            className="theme-btn-1 btn btn-block "
          >
            <i className="fas fa-arrow-left"></i>
            Voltar
          </button>
        </Link>
        <div className="row">
          <div className="col-12">
            <div className=" ltn__primary-color title-2">
              <h3 className="mb-10 go-top ltn__primary-color">
                Pedido Número {orderDetail?.order_number}
              </h3>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="ltn__checkout-inner">
              <div className="ltn__checkout-single-content ltn__returning-customer-wrap">
                <div id="ltn__returning-customer-login"></div>
              </div>
              <div className="ltn__checkout-single-content">
                <div>
                  <form>
                    <h6>Informações pessoais</h6>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        {/* <h6>Responsável</h6> */}
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__name"
                            placeholder={orderDetail?.customer_data?.user_name}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        {/* <h6>Empresa</h6> */}
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__company"
                            placeholder={
                              orderDetail?.customer_data.company_name
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        {/* <h6>Whatsapp</h6> */}
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="email"
                            name="ltn__phone"
                            placeholder={
                              orderDetail?.customer_data.user_cellphone
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        {/* <h6>Telefone</h6> */}
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__phone"
                            placeholder={
                              orderDetail?.customer_data.user_cellphone
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <form>
                    <h6>Endereço de entrega</h6>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__name"
                            placeholder={
                              orderDetail?.customer_data.delivery_address
                                .address
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__company"
                            placeholder={
                              orderDetail?.customer_data.delivery_address
                                .complement
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <h6>Estado</h6>
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__phone"
                            placeholder={
                              orderDetail?.customer_data.delivery_address.state
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <h6>Cidade</h6>
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__email"
                            placeholder={
                              orderDetail?.customer_data.delivery_address.city
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <h6>CEP</h6>
                        <div className="input-item input-item-lock ltn__custom-icon">
                          <input
                            type="text"
                            name="ltn__phone"
                            placeholder={
                              orderDetail?.customer_data.delivery_address
                                .zip_code
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className=" ltn__primary-color title-2">Itens selecionados</h2>
        <table className="shoping-cart-table table-responsive justify-content-center">
          <CartTableHeader />
          <tbody className="text-align align-center">
            {!orderDetail ? (
              <tr>
                <td className="px-5 cart-product-image text-center">
                  <h1
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    Nenhum item nesse pedido
                  </h1>
                </td>
              </tr>
            ) : (
              (orderDetail?.products_data || []).map((product, index) => (
                <tr key={index}>
                  <td className="px-5 cart-product-image text-center">
                    <Link to="/product-details">
                      <img src={hasImage(product.image)} alt={`${product.product_name}`} />
                    </Link>
                  </td>
                  <td className="px-5 cart-product-info">
                    <h4 className="ltn__secondary-color">
                      <Link to={'/product-detail/' + product.product_id}>{product.product_name}</Link>
                    </h4>
                  </td>
                  <td className="px-5 cart-product-price text-center">
                    {formatBRL(product.product_price)}
                  </td>
                  <td className="px-5 cart-product-subtotal ">
                    <div className="text-center row h-100 align-content-center ">
                      <div>
                        <input
                          type="number"
                          className="cart-plus-minus-box"
                          defaultValue={product.product_quantity}
                          disabled
                        />
                      </div>
                    </div>
                  </td>
                  <td className="px-5 cart-product-total text-center">
                    {formatBRL(product.product_total_price)}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="my-5">
          <h4 className="title-2">Método de pagamento</h4>
        </div>
        <div className="ltn__checkout-payment-method my-5 row">
          <div className="col-3 text-center">
            <button
              className={`theme-btn-1${orderDetail?.payment_method === "Boleto 14 dias"
                ? ""
                : "-opposite"
                }  btn my-1}`}
              disabled
            >
              Boleto de 14 dias
            </button>
          </div>
          <div className="col-3 text-center">
            <button
              className={`theme-btn-1${orderDetail?.payment_method === "Boleto 28 dias"
                ? ""
                : "-opposite"
                }  btn my-1}`}
              disabled
            >
              Boleto de 28 dias
            </button>
          </div>
          <div className="col-3 text-center">
            <button
              className={`theme-btn-1${orderDetail?.payment_method === "Boleto 45 dias"
                ? ""
                : "-opposite"
                } btn btn-effect-1 my-1`}
            >
              Boleto de 45 dias
            </button>
          </div>
          <div className="col-3 text-center">
            <button
              className={`theme-btn-1${orderDetail?.payment_method === "À vista com desconto"
                ? ""
                : "-opposite"
                } btn my-1`}
              disabled
            >
              À vista com desconto
            </button>
          </div>
        </div>
        <div
          className="row justify-content-end mb-5"
          style={{ padding: "16px" }}
        >
          <div className="shoping-cart-total mt-50 justify-content-end">
            <h4 className="title-2">Total do Pedido</h4>
            <table className="table">
              <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td>{orderDetail?.subtotal_price}</td>
                </tr>
                <tr>
                  <td>Frete</td>
                  <td>{orderDetail?.shipping_price}</td>
                </tr>
                <tr>
                  <td>Desconto</td>
                  <td style={{
                    color: 'green'
                  }}>
                    <strong>
                      -{orderDetail?.discount_price}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>{orderDetail?.total_price}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p className="mt-3">
                Nesse pedido você ganhou <strong style={{ color: 'green' }}>{orderDetail?.discount_cashback}</strong> de cashback, que poderá utilizar em sua próxima compra.
              </p>
            </div>
            <div className="row mt-1">
              <div className="col-8">
                <button
                  className="theme-btn-1 btn my-1 w-100"
                  style={{ backgroundColor: "#49A2D4" }}
                >
                  <i className="fa-solid fa-lg fa-file-pdf"></i>
                  Compartilhar PDF
                </button>
              </div>
              <div className="col-4">
                <button
                  className="btn my-1 w-100"
                  style={{
                    backgroundColor: "#25D366",
                    color: "#FFF",
                    borderRadius: "4px",
                  }}
                >
                  <i className="fa-brands fa-lg fa-whatsapp"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
