import React from "react";
import { Link } from "react-router-dom";
import { whatsapp_links } from "../../data/contact";

function ProductSliderV2() {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <div className="background-top-fade"></div>
      <div
        id="products"
        className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-1--- ltn__secondary-color text-align-center">
                  Produtos
                </h6>
                <h1 className="section-title text-align-center">
                  Mais de 7 mil produtos de diferentes segmentos
                </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__search-by-place-slider-1-active  go-top d-flex">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="product-item ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <a
                  href={whatsapp_links.ferramentas}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="category-icon">
                    <i className="fas fa-wrench"></i>
                  </span>
                  <span className="category-number">01</span>
                  <span className="category-title">Ferramentas</span>
                  <span className="category-brief">
                    As ferramentas certas fazem o trabalho ficar mais fácil.
                    Descubra nossa seleção de ferramentas de qualidade para
                    tornar cada tarefa uma brisa.
                  </span>
                  {/* <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span> */}
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="product-item ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <a
                  href={whatsapp_links.fixadores}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="category-icon">
                    <i className="fas fa-screwdriver"></i>
                  </span>
                  <span className="category-number">02</span>
                  <span className="category-title">Fixação</span>
                  <span className="category-brief">
                    Fixe com confiança! Encontre uma variedade de produtos de
                    fixação robustos e duráveis para manter tudo no lugar
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="product-item ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <a
                  href={whatsapp_links.quimicos}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="category-icon">
                    <i className="fas fa-flask"></i>
                  </span>
                  <span className="category-number">03</span>
                  <span className="category-title">Químicos</span>
                  <span className="category-brief">
                    Explore nossa gama de produtos químicos confiáveis para
                    manter tudo funcionando suavemente. Da limpeza à manutenção,
                    temos o que você precisa.
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="product-item ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <a
                  href={whatsapp_links.estoque}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="category-icon">
                    <i className="fas fa-truck-fast" />
                  </span>
                  <span className="category-number">04</span>
                  <span className="category-title">Conheça nosso estoque</span>
                  <span className="category-brief">
                    Descubra um mundo de possibilidades em nosso estoque
                    completo. Explore agora e encontre o que você precisa para
                    fazer negócios de forma eficaz e eficiente!
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSliderV2;
