import React from "react";
import { Link } from "react-router-dom";

const FinalizationCart = () => {
  
  return (
    <div
      className="container"
      style={{
        display: "flex",
        padding: "3rem 0px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i
        className="fa-solid fa-circle-check primary"
        style={{ fontSize: "150px", color: "#77C720" }}
      ></i>
      <h1 className="ltn__primary-color m-3">Seu pedido foi encaminhado!</h1>
      <h4 style={{ margin: "1rem", textAlign: "center", marginTop: '2rem' }}>
        Acompanhe o status do seu pedido através do Histórico de Pedidos na sua
        conta<br/>ou continue a navegar em nosso site.
      </h4>
      <Link
        to="/myAccount/orderHistory"
        className="theme-btn-1 btn btn-effect-1"
        style={{
          maxWidth: "400px",
          marginTop: '2rem'
        }}
      >
        <i className="fa-solid fa-arrow-left"></i>
        Ir para o histórico de pedidos
      </Link>
      <Link className="mt-3" to={'/products'}>Voltar para produtos</Link>
    </div>
  );
};

export default FinalizationCart;
