import React from "react";
import { ContactSeller } from "./ContactSeller";
import useMyAccount from "../../../hooks/useMyAccount";

const MyContactTerra = () => {
  const { contactTerra } = useMyAccount();
  return (
    <div className="tab-pane fade active show" id="tab_2">
      {contactTerra ? (
        <div className="ltn__myaccount-tab-content-inner">
          <p>Esse é o contato do vendedor Terra designado para te atender.</p>
          <div className="ltn__comment-area mb-50">
            <ContactSeller />
          </div>
        </div>
      ) : (
        <div className="ltn__myaccount-tab-content-inner">
          <p>
            Você não tem vendedor Terra designado para te atender. Mande um
            e-mail para adm@matrixterra.com.br {contactTerra?.whatsapp && ` ou nos contate por ${contactTerra?.whatsapp}`}
          </p>
        </div>
      )}
    </div>
  );
};
export default MyContactTerra;
