import React from "react";
import ProductFilters from "./components/ProductFilters";
import ProductList from "./components/ProductList";
import Pagination from "../MyAccount/components/Pagination";
const Products = () => {
  return (
    <div className="container">
      <div className="row row-cols-lg-2 row-cols-sm-1">
        <ProductFilters />
        <ProductList />
      </div>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Pagination />
      </div>
    </div>
  );
};
export default Products;
