import React from "react";
import { useLocation } from "react-router";
import userImage from "../../../../data/user_icon.jpg";
import { stripNonNumeric } from "../../../../util/stripNonNumeric";

export const CollaboratorDetails = () => {
  const { state } = useLocation();
  const collaboratorDetails = state?.collaborator;

  return (
    <div>
      <div className="ltn-author-introducing clearfix">
        <div className="author-img">
          <img
            style={custom_styles.imgStyle}
            src={
              collaboratorDetails.image
                ? `data:image/png;base64,${collaboratorDetails?.image}`
                : userImage
            }
            alt="Collaborator"
          />
        </div>
        <div className="author-info">
          <h6>Colaborador</h6>
          <h2 style={custom_styles.headerNameStyle}>
            {collaboratorDetails?.full_name}
          </h2>
          <div className="footer-address">
            <ul>
              <li>
                <div className="footer-address-icon">
                  <i className="fab fa-whatsapp" style={custom_styles.iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    <a
                      href={`https://wa.me/55${stripNonNumeric(collaboratorDetails?.whatsapp)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {collaboratorDetails?.whatsapp}
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="far fa-envelope" style={custom_styles.iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    <a href={`mailto:${collaboratorDetails?.email}`}>
                      {collaboratorDetails?.email}
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="fas fa-briefcase" style={custom_styles.iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>{collaboratorDetails?.company_name}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style={custom_styles.detailsContainerStyle}>
        <div className="footer-address">
          <ul>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-user" style={custom_styles.iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Nome: </strong>
                  {collaboratorDetails?.full_name}
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="fab fa-whatsapp" style={custom_styles.iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Whatsapp: </strong>
                  <a
                    href={`https://wa.me/55${stripNonNumeric(collaboratorDetails?.whatsapp)}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {collaboratorDetails?.whatsapp}
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i
                  className="fas fa-phone fa-rotate-90"
                  style={custom_styles.iconStyle}
                />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Telefone: </strong>
                  <a href={`tel:55${stripNonNumeric(collaboratorDetails?.cellphone)}`}>
                    {collaboratorDetails?.cellphone}
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-address">
          <ul>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-envelope" style={custom_styles.iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Email: </strong>
                  <a href={`mailto:${collaboratorDetails?.email}`}>
                    {collaboratorDetails?.email}
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-building" style={custom_styles.iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Empresa: </strong>
                  <span style={custom_styles.companyNameStyle}>
                    {collaboratorDetails?.company_name}
                  </span>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-id-badge" style={custom_styles.iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Função: </strong>
                  {collaboratorDetails?.function}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const custom_styles = {
  iconStyle: { color: "#EC1F25" },
  imgStyle: {
    width: "180px",
    height: "180px",
    objectFit: "cover",
  },
  headerNameStyle: { color: "#071C1F" },
  companyNameStyle: {
    fontWeight: 700,
    color: "var(--ltn__secondary-color)",
  },
  detailsContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
};

