import React from "react";
import { useState } from "react";
import KeywordFilter from "../KeywordFilter";
import useMyAccount from "../../../../hooks/useMyAccount";
import CollaboratorComponent from "./components/CollaboratorComponent";

export const Collaborators = () => {
  const [keyword, setKeyword] = useState("");
  const { collaborators } = useMyAccount();

  const handleFilterChange = (event) => {
    setKeyword(event.target.value);
  };

  return (
    <div className="ltn__my-properties-table table-responsive">
      <KeywordFilter keyword={keyword} onFilterChange={handleFilterChange} />
      <table className="table" style={styles.table}>
        <thead style={styles.tableHead}>
          <tr>
            <th scope="col">Unidade</th> <th scope="col" />
          </tr>
        </thead>
        <tbody
          style={styles.tableBody}
        >
          {collaborators
            .filter((collaborator) =>
              collaborator.full_name.toUpperCase().includes(keyword.toUpperCase())
            ).map((collaborator, index) => <CollaboratorComponent collaborator={collaborator} key={index} />)}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  table: { marginTop: "20px" },
  tableHead: {
    borderRight: "hidden",
    borderLeft: "hidden",
    borderTop: "hidden",
  },
  tableBody: {
    borderRight: "hidden",
    borderLeft: "hidden",
    fontSize: "14px",
  }
};
