import React from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../components/FormComponents/Input";
import useMyAccount from "../../../../hooks/useMyAccount";
import userIcon from "../../../../data/user_icon.jpg";
import { stripNonNumeric } from "../../../../util/stripNonNumeric";

const UserProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const iconStyle = { color: "#EC1F25" };
  const { userData } = useMyAccount();

  return (
    <div>
      <div className="ltn-author-introducing clearfix">
        <div className="author-img">
          <img
            src={
              userData?.image
                ? `data:image/png;base64,${userData?.image}`
                : userIcon
            }
            alt="Author"
          />
        </div>
        <div className="author-info">
          <h6>Gerente geral</h6>
          <h2 style={{ color: "#071C1F" }}>{userData?.user_name}</h2>
          <div className="footer-address">
            <ul>
              <li>
                <div className="footer-address-icon">
                  <i className="fab fa-whatsapp" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    {userData?.whatsapp}
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="far fa-envelope" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    {userData?.email}
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="fas fa-briefcase" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    {userData?.company_name
                      ? userData.company_name
                      : "Não informado"}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <form id="contact-form" action="mail.php" method="post">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h6>Nome completo</h6>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="name"
                placeholder={userData?.user_name}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h6>Função</h6>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="contactFunction"
                placeholder={userData?.function}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h6>Whatsapp</h6>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="phone"
                placeholder={userData?.whatsapp}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <h6>Telefone</h6>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="phone"
                placeholder={userData?.phone}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h6>E-mail</h6>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="email"
                register={register}
                fieldName="email"
                placeholder={userData?.email}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h6>Empresa</h6>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="companyName"
                placeholder={userData?.company_name}
                errors={errors}
                disabled
              />
            </div>
          </div>
        </div>
        <p className="form-messege mb-0 mt-20" />
      </form>
    </div>
  );
};

export default UserProfile;
