import React from "react";
import styles from "../../MyAccount.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import { useMyAccount } from "../../../../hooks/useMyAccount";
import { useHttp } from "../../../../hooks/useHttp";

const RequestCodeForm = () => {
  const { userId } = useAuth();
  const { userData } = useMyAccount();
  const { sendRequest } = useHttp();
  const navigate = useNavigate();

  const handleSendCode = async (event) => {
    event.preventDefault();
    try {

      const response = await sendRequest(
        "https://matrixterra.com.br/ws/customer/password-change/send_code_password.php",
        "POST",
        JSON.stringify({
          email: userData?.email,
          uid: userId,
        }),
        {
          "Content-Type": "application/json",
          "Access-Control-Request-Method": "application/postscript",
        }
      );

      if (response.success) {
        navigate("verificationCode");
      };

    } catch (error) {
      throw error;
    };

  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      <div className="account-login-inner">
        <form action="#" className="ltn__form-box contact-form-box">
          <h5 className="title-2 mb-30">
            Altere sua senha através do e-mail
            <p className={styles.description}>
              Ao clicar no botão abaixo, enviaremos um código de alteração de
              senha.
            </p>
          </h5>
          <div className="btn-wrapper mt-0">
            <button
              disabled={!userData?.email}
              onClick={(e) => handleSendCode(e)}
              className="theme-btn-1 btn btn-block"
            >
              Enviar código de alteração para e-mail
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestCodeForm;
