import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import HomeV1 from "./components/home-v1";

import MyAccount from "./pages/MyAccount";
import CallToActonV1 from "./components/section-components/call-to-action-v1";
import Auth from "./pages/Auth";
import Products from "./pages/Products";
import TopBar from "./components/global-components/topbar";
import Footer_v1 from "./components/global-components/footer";
import ContextProvider from "./util/ContextProvider";
import LayoutBanner from "./components/LayoutBanner";
import Cart from "./pages/Cart";
import CheckoutLayout from "./pages/CheckoutLayout";
import MainNavbar from "./components/MainNavbar";
import { Toaster } from "react-hot-toast";
import OrderDetails from "./pages/OrderDetails";
import { Route, Routes } from "react-router";
import MyContactTerra from "./pages/MyAccount/components/MyContactTerra";
import MyAccountCollab from "./pages/MyAccount/components/MyAccountColab";
import MyAccountOrderHistory from "./pages/MyAccount/components/MyAccountOrderHistory";
import MyAccountPaymentDelivery from "./pages/MyAccount/components/MyAccountPaymentDelivery";
import ChangePasswordForm from "./pages/MyAccount/components/ChangePasswordForm";
import MyAccountLogout from "./pages/MyAccount/components/MyAccountLogout";
import MyAccountCollabDetails from "./pages/MyAccount/components/MyAccountCollabDetails";
import MyClients from "./pages/MyClients";
import MyClientsCollaborators from "./pages/MyClients/MyClientsCollaborators";
import VerificationCodeForm from "./pages/MyAccount/components/VerificationCodeForm";
import RequestCodeForm from "./pages/MyAccount/components/RequestCodeForm";
import MyAccountDetailsOrderHistory from "./pages/MyAccount/components/MyAccountDetailsOrderHistory";
import MyAccountUserProfile from "./pages/MyAccount/components/MyAccountUserProfile";
import MyAccountDetailsCompany from "./pages/MyAccount/components/MyAccountDetailsCompany";
import { Navigate } from "react-router-dom";
import FinalizationCart from "./pages/FinalizationCart.js";
import { useLocation } from "react-router-dom";
import ProductDetail from "./pages/Product-Detail/index.js";
import ResetPassword from "./pages/ResetPassword/index.js";

export default function Root() {
  const userToken = JSON.parse(localStorage.getItem("userData"));
  const isLogged = () => {
    return Boolean(userToken?.token ?? null);
  };

  const isUserTerra = () => {
    return Boolean(userToken?.is_user_terra ?? null);
  };

  const { pathname } = useLocation();

  const PrivateRoute = ({ element, isLogged }) => {
    return isLogged ? (
      element
    ) : (
      <Navigate to="/" replace state={{ from: window.location.pathname }} />
    );
  };

  useEffect(() => {
    if (pathname === "/authentication") {
      window.scrollTo(0, 400);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const Layout = ({ children }) => {
    return <LayoutBanner>{children}</LayoutBanner>;
  };

  const ComponentProvider = ({ children }) => {

    return (
      <>
        <Toaster />
        <TopBar />
        <MainNavbar />
        {
          children
        }
        {
          !isLogged() && <CallToActonV1 />
        }
        <Footer_v1 />
      </>
    )
  };

  return (
    <ComponentProvider>
      <Routes>
        <Route exact path="/" element={<HomeV1 />} />
        <Route
          exact
          path="/authentication"
          element={
            <Layout>
              <Auth />
            </Layout>
          }
        />
        <Route
          exact
          path="/resetPassword"
          element={
            <Layout>
              <ResetPassword />
            </Layout>
          }
        />
        {
          <Route
            exact
            path="/products"
            element={
              <Layout>
                <Products />
              </Layout>
            }
          />
        }
        <Route
          exact
          path="/product-detail/:id"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <ProductDetail />
                </Layout>
              }
              isLogged={isLogged()}
            />
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <Cart />
                </Layout>
              }
              isLogged={isLogged()}
            />
          }
        />
        <Route
          path="/finalizationCart"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <FinalizationCart />
                </Layout>
              }
              isLogged={isLogged()}
            />
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <CheckoutLayout />
                </Layout>
              }
              isLogged={isLogged()}
            />
          }
        />
        <Route
          exact
          path="/myaccount"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <MyAccount />
                </Layout>
              }
              isLogged={isLogged() && !isUserTerra()}
            />
          }
        >
          <Route path="main" element={<MyAccountUserProfile />} />

          <Route
            path="main/detailsCompany"
            element={<MyAccountDetailsCompany />}
          />
          <Route path="contact" element={<MyContactTerra />} />
          <Route path="collab" element={<MyAccountCollab />} />
          <Route
            path="collab/collabDetails"
            element={<MyAccountCollabDetails />}
          />
          <Route path="orderHistory" element={<MyAccountOrderHistory />} />
          <Route
            path="paymentDelivery"
            element={<MyAccountPaymentDelivery />}
          />
          <Route
            path="requestCode/verificationCode/changePassword"
            element={<ChangePasswordForm />}
          />
          <Route path="requestCode" element={<RequestCodeForm />} />
          <Route
            path="requestCode/verificationCode"
            element={<VerificationCodeForm />}
          />
          <Route path="logout" element={<MyAccountLogout />} />
        </Route>
        <Route
          path="/detailsOrderHistory"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <MyAccountDetailsOrderHistory />
                </Layout>
              }
              isLogged={isLogged()}
            />
          }
        />
        <Route
          exact
          path="/myclients"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <MyClients />
                </Layout>
              }
              isLogged={isLogged() && isUserTerra()}
            />
          }
        />
        <Route
          exact
          path="/myclients/:myClientsCollabId"
          element={
            <PrivateRoute
              element={
                <Layout>
                  <MyClientsCollaborators />
                </Layout>
              }
              isLogged={isLogged() && isUserTerra()}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ComponentProvider>
  );
}

ReactDOM.render(
  <BrowserRouter basename="/">
    <ContextProvider>
      <Root />
    </ContextProvider>
  </BrowserRouter>,
  document.getElementById("quarter")
);
