import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useCart from "./../../../hooks/useCart";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { hasImage } from "../../Products/components/ProductItem";
import { formatBRL } from "../../../util/formatPriceBRL";

const validationSchema = yup.object().shape({
  quantity: yup.number().min(0).integer(),
});

const CartTableItem = (props) => {
  const { dispatch } = useCart();

  const { register, handleSubmit } = useForm({
    defaultValues: { quantity: props.obj.quantity },
    resolver: yupResolver(validationSchema),
  });

  const changeCartItemQuantity = (value) => {
    if (value === 0) {
      dispatch({
        type: "DELETE",
        obj: props.obj,
      });
    } else {
      dispatch({
        type: "CHANGE_QUANTITY",
        obj: props.obj,
        quantity: value,
      });
    }
  };
  const onSubmit = (data) => {
    changeCartItemQuantity(data.quantity);
  };

  return (
    <tr>
      <td className="px-5 cart-product-image text-center">
        <Link to={`/product-detail/${props.obj.id}`}>
          <img
            src={hasImage(props.obj.image)}
            alt="#"
            style={{ height: "90px" }}
          />
        </Link>
      </td>
      <td className="px-5 cart-product-info">
        <h4 className="ltn__secondary-color">
          <Link to={`/product-detail/${props.obj.id}`}>
            {props.obj.product_name}
          </Link>
        </h4>
        <p>{props.obj.description}</p>
      </td>
      <td className="px-5 cart-product-price text-center">
        {formatBRL(props.obj.price)}
      </td>
      <td className="px-5 cart-product-subtotal ">
        <div className="text-center row h-100 align-content-center ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <i
              className="fa-solid fa-circle-info"
              title="Você pode mudar a quantidade de items de cada produto."
            ></i>
            <input
              type="number"
              {...register("quantity")}
              className="cart-plus-minus-box border mx-1"
            />
          </form>
        </div>
      </td>
      <td className="px-5 cart-product-total text-center">
        {formatBRL(props.obj.quantity * props.obj.price)}
      </td>
    </tr>
  );
};
export default CartTableItem;
