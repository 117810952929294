import React from "react";

const KeywordFilter = ({ keyword, onFilterChange }) => {
  return (
    <div style={{ position: "relative", borderRadius: "none" }}>
      <input
        type="text"
        id="filter"
        value={keyword}
        onChange={onFilterChange}
        placeholder="Pesquise por nome, descrição ou informação relacionada"
        style={{ borderRadius: "0" }}
      />
    </div>
  );
};

export default KeywordFilter;
