import React from "react";
import UserProfile from "./UserProfile";
import CompanyProfile from "./CompanyProfile";
import useMyAccount from "../../../hooks/useMyAccount";

const MyAccountUserProfile = () => {
  const { contactTerra } = useMyAccount();

  return (
    <div className="tab-pane fade active show" id="tab_1">
      <div className="ltn__myaccount-tab-content-inner">
        <p>
          Caso queira modificar alguma informação, mande um e-mail para
          adm@matrixterra.com.br {contactTerra?.whatsapp && ` ou nos contate por ${contactTerra?.whatsapp}`}.
        </p>
      </div>
      <UserProfile />
      <div className="ltn__myaccount-tab-content-inner">
        <p>Minha empresa</p>
      </div>
      <CompanyProfile showDetailsButton={true} />
    </div>
  );
};

export default MyAccountUserProfile;
