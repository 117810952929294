import React from "react";
import useCart from "../../../hooks/useCart";
import { useAuth } from "../../../hooks/useAuth";
import { formatBRL } from "../../../util/formatPriceBRL";

const TotalCartTable = ({ showCashbackMessage }) => {
  const { cart, totalValue, orderFinalValue, cashback, setCashback } =
    useCart();

  const cashbackHandler = () => {
    setCashback((prev) => !prev);
  };

  const { userInfo, assistedPurchaseState } = useAuth();

  const CashbackMessage = () => (
    <div
      className="row"
      style={{
        justifyContent: "center",
        marginBottom: "10px",
      }}
    >
      <div
        id="checkbox"
        className="col-1"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "20px",
        }}
      >
        <input
          checked={cashback}
          type="checkbox"
          style={{ width: "20px" }}
          onChange={cashbackHandler}
        />
      </div>
      <div
        className="col-11"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1px 0px",
        }}
      >
        <label htmlFor="checkbox" style={{ fontSize: "14px" }}>
          {`
          A Unidade selecionada tem disponível ${assistedPurchaseState
              ? formatBRL(assistedPurchaseState?.client_cashback_amount)
              : formatBRL(userInfo?.cashback_amount)
            } de cashback. Para utilizar o valor nesta compra, selecione a caixa ao lado. 
          `}
        </label>
      </div>
    </div>
  );
  const WarningMessage = () => (
    <div
      className="row"
      style={{
        justifyContent: "center",
        marginBottom: "10px",
      }}
    >
      <div
        className="col-1"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "20px",
        }}
      >
        <i class="fa-solid fa-triangle-exclamation"></i>
      </div>
      <div
        className="col-11"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1px 0px",
        }}
      >
        <label htmlFor="checkbox" style={{ fontSize: "14px" }}>
          Possíveis impostos já estão incluídos no valor apresentado nesta tela. Para mais informações, entrar em contato com o setor administrativo.
        </label>
      </div>
    </div>
  );
  return (
    <>
      {cart.length !== 0 && (
        <div style={{ width: "100%" }}>
          <h3 className="ltn__primary-color">Total do Carrinho</h3>
          {(assistedPurchaseState
            ? assistedPurchaseState.client_cashback_amount
            : userInfo?.cashback_amount) < totalValue() &&
            (assistedPurchaseState
              ? assistedPurchaseState.client_cashback_amount
              : userInfo?.cashback_amount) !== 0 &&
            (showCashbackMessage ? <CashbackMessage /> : <WarningMessage />)}
          <table className="table">
            <tbody>
              <tr>
                <td>Subtotal</td>
                <td>{formatBRL(totalValue())}</td>
              </tr>
              <tr>
                <td>Frete</td>
                <td>
                  {assistedPurchaseState
                    ? formatBRL(assistedPurchaseState?.clientFrete)
                    : formatBRL(userInfo?.frete)}
                </td>
              </tr>
              <tr>
                <td>Desconto</td>
                <td style={{
                  color: 'green'
                }}>
                  <strong>
                    -{assistedPurchaseState
                      ? formatBRL(
                        (assistedPurchaseState.client_discount_percentage *
                          totalValue()) /
                        100
                      )
                      : formatBRL(
                        (userInfo?.discount_percentage * totalValue()) / 100
                      )}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>{formatBRL(orderFinalValue())}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mt-3">
            Nesta compra você ganhará <strong style={{ color: 'green' }}>
              {assistedPurchaseState
                ? formatBRL(
                  (assistedPurchaseState.client_cashback_percentage *
                    totalValue()) /
                  100
                )
                : formatBRL(
                  (userInfo?.cashback_percentage * totalValue()) / 100
                )}
            </strong> de cashback.
          </p>
        </div>
      )}
    </>
  );
};
export default TotalCartTable;
